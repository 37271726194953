import { DashboardContainer, FiltrosContainer, GraficosContainer, PedidosLevaContainer, SelectContainer } from './styles';
import { ReactSVG } from 'react-svg';
import DashSvg from '../../assets/dash.svg';
import DownloadSvg from '../../assets/download.svg';
import api from '../../services/api';
import { useEffect } from 'react';
import { useState } from 'react';
import { useAuth } from '../../providers/Auth';
import { useNavigate } from 'react-router-dom';
import TotalPedidosAno from '../Graficos/TotalPedidosAno';
import OperacoesAtivas from '../Graficos/OperacoesAtivas';
import TotalPedidos from '../Graficos/TotalPedidos';
import PedidosPorComercial from '../PedidosPorComercial';
import PedidosPorOperacao from '../PedidosPorOperacao';
import PedidosPorPdv from '../PedidosPorPdv';
import Loading from '../Loading';
import PedidosLevas from '../PedidosLevas';
import { ReactComponent as HomeFiltroSvg } from '../../assets/home-filtro.svg';
import Select from 'react-select';
import * as XLSX from "xlsx";
import PedidosPorGeo from '../PedidosPorGeo';

const Dashboard = () => {
    const [clienteOptions, setClienteOptions] = useState([]);
    const [clienteSelecionado, setClienteSelecionado] = useState('');

    const [geosOptions, setGeosOptions] = useState([]);
    const [geoSelecionada, setGeoSelecionada] = useState('');

    const [operacoesOptions, setOperacoesOptions] = useState([]);
    const [operacoesOptionFiltradas, setOperacoesOptionFiltradas] = useState([]);
    const [operacaoSelecionada, setOperacaoSelecionada] = useState('');

    const [pedidos, setPedidos] = useState([]);
    const [pedidosFiltrados, setPedidosFiltrados] = useState([]);

    const [operacoes, setOperacoes] = useState([]);
    const [operacoesFiltradas, setOperacoesFiltradas] = useState([]);

    const [levas, setLevas] = useState([]);
    const [levaAtual, setLevaAtual] = useState('');
    const [levasAtivas, setLevasAtivas] = useState([]);

    const [anos, setAnos] = useState([]);
    const [anoSelecionado, setAnoSelecionado] = useState('');

    const [clientesLoaded, setClientesLoaded] = useState(false);
    const [geosLoaded, setGeosLoaded] = useState(false);
    const [pedidosLoaded, setPedidosLoaded] = useState(false);
    const [operacoesLoaded, setOperacoesLoaded] = useState(false);
    const [levasLoaded, setLevasLoaded] = useState(false);
    const [loading, setLoading] = useState(true);

    const { logout, user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (pedidosLoaded && operacoesLoaded && levasLoaded) {
            setLoading(false);
        };
    }, [clientesLoaded, geosLoaded, pedidosLoaded, operacoesLoaded, levasLoaded]);
    
    const buscarPedidos = () => {
        setPedidosLoaded(false);
        api.get(`/pedidos`).then((resp)=>{
            const pedidosResp = resp.data.data.pedidos;
            setPedidos(pedidosResp);
            setPedidosLoaded(true);
        }).catch((error)=>{
            if (error.response?.status === 401){
                logout();
                navigate('/');
            };
        });
    };
    const buscarOperacoes = () => {
        setOperacoesLoaded(false);
        api.get('/operacoes')
            .then((response) => {
                const operacoes = response.data.data.operacoes;
                const operacoesOptions = operacoes.map((operacao) => {
                    return {
                        value: operacao.id,
                        label: operacao.nome,
                        geo_id: operacao.geo_id,
                        comercial_id: operacao.comercial_id,
                    };
                });
                setOperacoesOptions(operacoesOptions);
                setOperacoesOptionFiltradas(operacoesOptions);
                setOperacoes(operacoes);
                setOperacoesLoaded(true);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                };
            });
    };
    const buscarLevas = () => {
        setLevasLoaded(false);
        api.get('/levas')
            .then((response) => {
                setLevas(response.data.data.levas);
                setLevasLoaded(true);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                };
            });
    };

    useEffect(()=>{
        buscarPedidos();
        buscarOperacoes();
        buscarLevas();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const buscarClientes = () => {
            api.get('/clientes')
                .then((response) => {
                    const clientes = response.data.data.clientes;
                    const clientesOptions = clientes.map((cliente) => {
                        return {
                            value: cliente.id,
                            label: cliente.nome
                        };
                    });
                    setClienteOptions(clientesOptions);
                    setClientesLoaded(true);
                }).catch((error) => {
                    if (error.response?.status === 401){
                        logout();
                        navigate('/');
                    };
                });
        };
        const buscarGeos = () => {
            api.get('/geos')
                .then((response) => {
                    const geos = response.data.data.geos;
                    const geosOptions = geos.map((geo) => {
                        return {
                            value: geo.id,
                            label: geo.nome
                        };
                    });
                    setGeosOptions(geosOptions);
                    setGeosLoaded(true);
                }).catch((error) => {
                    if (error.response?.status === 401){
                        logout();
                        navigate('/');
                    };
                });
        };
        if (user.permissao === 'mari') {
            buscarClientes();
            buscarGeos();
        } else if (user.permissao === 'ac') {
            setClientesLoaded(true);
            buscarGeos();
        } else {
            setClientesLoaded(true);
            setGeosLoaded(true);
        };
    },[user]);

    useEffect(() => {
        if(pedidosLoaded && operacoesLoaded){
            let anos = [];
            pedidos.map((elem) => {
                const [,,ano] = elem.data_de_criacao.split(' ')[0].split('/');
                if(!anos.includes(ano)){
                    anos.push(ano);
                };
            });
            operacoes.map((elem) => {
                const [,,ano] = elem.data_de_criacao.split(' ')[0].split('/');
                if(!anos.includes(ano)){
                    anos.push(ano);
                };
            });
            
            setAnoSelecionado('');
            setAnos(anos.sort().reverse());
            setPedidosFiltrados(pedidos);
            setOperacoesFiltradas(operacoes);
        }

        //eslint-disable-next-line react-hooks/exhaustive-deps
    },[pedidosLoaded, operacoesLoaded]);

    useEffect(() => {
        const pedidosAno = pedidos.filter((elem) => {
            const [,,ano] = elem.data_de_criacao.split(' ')[0].split('/');
            return ano === anoSelecionado;
        });
        const operacoesAno = operacoes.filter((elem) => {
            const [,,ano] = elem.data_de_criacao.split(' ')[0].split('/');
            return ano === anoSelecionado;
        });

        setPedidosFiltrados(anoSelecionado ? pedidosAno : pedidos);
        setOperacoesFiltradas(anoSelecionado ? operacoesAno : operacoes);
    }, [anoSelecionado]);

    useEffect(() => {
        const pedidosFiltrados = pedidos.filter((elem) => {
            const filtroGeo = geoSelecionada ? String(elem.geo_id) === String(geoSelecionada.value)  : true;
            const filtroOperacao = operacaoSelecionada ? String(elem.operacao_id) === String(operacaoSelecionada.value)  : true;

            return filtroGeo && filtroOperacao;
        });
        const operacoesFiltradas = operacoes.filter((elem) => {
            const filtroGeo = geoSelecionada ? String(elem.geo_id) === String(geoSelecionada.value)  : true;

            return filtroGeo;
        });
        if (geoSelecionada) {
            const geoOperacoesOptions = operacoesOptions.filter((elem) => String(elem.geo_id) === String(geoSelecionada.value));
            setOperacoesOptionFiltradas(geoOperacoesOptions);
            setOperacaoSelecionada('');
            const [levaAtivaGeo] = levasAtivas.filter((elem) => String(elem.geo_id) === String(geoSelecionada.value));
            if (levaAtivaGeo) {
                setLevaAtual(levaAtivaGeo);
            } else {
                setLevaAtual('');
            };
        } else {
            setLevaAtual('');
            setOperacaoSelecionada('');
        };
        setPedidosFiltrados(pedidosFiltrados);
        setOperacoesFiltradas(operacoesFiltradas);
    }, [geoSelecionada]);

    useEffect(() => {
        const pedidosFiltrados = pedidos.filter((elem) => {
            const filtroGeo = geoSelecionada ? String(elem.geo_id) === String(geoSelecionada.value)  : true;
            const filtroOperacao = operacaoSelecionada ? String(elem.operacao_id) === String(operacaoSelecionada.value)  : true;
            return filtroGeo && filtroOperacao;
        });
        if (!geoSelecionada && !operacaoSelecionada) {
            setOperacoesOptionFiltradas(operacoesOptions);
        }
        setPedidosFiltrados(pedidosFiltrados);
    }, [geoSelecionada, operacaoSelecionada]);

    useEffect(() => {
        if (levas.length) {
            if (!['mari', 'ac'].includes(user.permissao)) {
                const [ultimaLeva] = levas;
                if (!ultimaLeva.data_encerramento) {
                    setLevaAtual(ultimaLeva);
                } else {
                    setLevaAtual('');
                };
            } else {
                const levasAtivas = levas.filter((elem) => {
                    return !elem.data_encerramento;
                });
                setLevasAtivas(levasAtivas);
            };
        };
        //eslint-disable-next-line react-hooks/exhaustive-deps
    },[levasLoaded]);

    const gerarRelatorio = () => {
        const relatorio = pedidosFiltrados.filter((pedido) => ['CONCLUIDO','BAIXADO'].includes(pedido.status)).map((pedido) => {
            const pedidoLeva = levas.find((elem) => String(elem.id) === String(pedido.leva_id));

            const output = {
                'PEDIDO': pedido.id,
                'TÍTULO': pedido.titulo,
                'TEMPLATE': pedido.template,
                'QUANTIDADE': pedido.quantidade,
                'QUANTIDADE_QRCODE': pedido.quantidade_qrcode,
                'STATUS': pedido.status,
                'APROVADOR': pedido.valor,
                'PONTO DE VENDA' : pedido.ponto_de_venda,
                'DATA_CRIAÇÃO': pedido.data_de_criacao.split(' ')[0],
                'DATA_ATUALIZAÇÃO': pedido.data_de_atualizacao.split(' ')[0],
                'LEVA': pedido.leva_id,
                'DATA_LEVA': pedidoLeva ? pedidoLeva.data_de_criacao.split(' ')[0] : '',
                'DATA_ENCERRAMENTO': pedidoLeva.data_encerramento ? pedidoLeva.data_encerramento.split(' ')[0] : '',
            };

            if (user.permissao === 'ac') {
                output['GEO'] = pedidoLeva.geo_nome;
            };

            return output;
        });

        const ws = XLSX.utils.json_to_sheet(relatorio);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Relatório');
        XLSX.writeFile(wb, `RelatorioGeralCardapio${anoSelecionado ? anoSelecionado : ''}.xlsx`);
    }

    return (
        <DashboardContainer>
            <div className='header'>
                <ReactSVG src={DashSvg}/> <h1>Dashboard</h1> 
            </div>
            {loading ? (
                <Loading />
            ) : (
                <div className='body'>
                    <button className='extrair' onClick={gerarRelatorio}>
                        <ReactSVG src={DownloadSvg} /> Extrair relatório pedidos
                    </button>
                    <FiltrosContainer>
                        {user.permissao === 'mari' && (
                            <SelectContainer>
                                <label>
                                    <HomeFiltroSvg /> Filtrar por Cliente
                                </label>
                                <Select 
                                    options={clienteOptions}
                                    value={clienteSelecionado}
                                    onChange={(e) => setClienteSelecionado(e)}
                                    placeholder='Ver todos'
                                    className='filtro-select-container'
                                    classNamePrefix='filtro-select'
                                    isClearable
                                    noOptionsMessage={() => 'Nenhuma operação encontrada'}
                                />
                            </SelectContainer>
                        )}
                        {['mari', 'ac'].includes(user.permissao) && (
                            <SelectContainer>
                                <label>
                                    <HomeFiltroSvg /> Filtrar por Geo
                                </label>
                                <Select 
                                    options={geosOptions}
                                    value={geoSelecionada}
                                    onChange={(e) => setGeoSelecionada(e)}
                                    placeholder='Ver todos'
                                    className='filtro-select-container'
                                    classNamePrefix='filtro-select'
                                    isClearable
                                    noOptionsMessage={() => 'Nenhuma geo encontrada'}
                                />
                            </SelectContainer>
                        )}
                        {['mari', 'ac','admin'].includes(user.permissao) && (
                            <SelectContainer>
                            <label>
                                <HomeFiltroSvg /> Filtrar por operação
                            </label>
                            <Select 
                                options={operacoesOptionFiltradas}
                                value={operacaoSelecionada}
                                onChange={(e) => setOperacaoSelecionada(e)}
                                placeholder='Ver todos'
                                className='filtro-select-container'
                                classNamePrefix='filtro-select'
                                isClearable
                                noOptionsMessage={() => 'Nenhuma operação encontrada'}
                            />
                        </SelectContainer>
                        )}
                        <select value={anoSelecionado} onChange={(e) => setAnoSelecionado(e.target.value)}>
                            <option value=''>total</option>
                            {anos.map((elem) => {
                                return <option key={elem} value={elem}>{elem}</option>
                            })}
                        </select>   
                    </FiltrosContainer>
                    <GraficosContainer>
                        <TotalPedidosAno pedidos={pedidosFiltrados} anoSelecionado={anoSelecionado} />
                        <TotalPedidos pedidos={pedidosFiltrados} anoSelecionado={anoSelecionado} />
                        <OperacoesAtivas operacoes={operacoesFiltradas} anoSelecionado={anoSelecionado} />
                    </GraficosContainer>
                    <PedidosLevas pedidos={pedidos} levaAtual={levaAtual} levasAtivas={levasAtivas} geoSelecionada={geoSelecionada}/>
                    {user.permissao === 'ac' && (
                        <PedidosPorGeo pedidos={pedidosFiltrados} levaAtual={levaAtual} levasAtivas={levasAtivas} anoSelecionado={anoSelecionado} geoSelecionada={geoSelecionada}/>
                    )}
                    <PedidosPorComercial pedidos={pedidosFiltrados} levaAtual={levaAtual} levasAtivas={levasAtivas} anoSelecionado={anoSelecionado} geoSelecionada={geoSelecionada} operacaoSelecionada={operacaoSelecionada}/>
                    <PedidosPorOperacao pedidos={pedidosFiltrados} levaAtual={levaAtual}  levasAtivas={levasAtivas} anoSelecionado={anoSelecionado} geoSelecionada={geoSelecionada} operacaoSelecionada={operacaoSelecionada}/>
                    <PedidosPorPdv pedidos={pedidosFiltrados} levaAtual={levaAtual}  levasAtivas={levasAtivas} anoSelecionado={anoSelecionado} geoSelecionada={geoSelecionada} operacaoSelecionada={operacaoSelecionada}/>
                </div>
            )}
        </DashboardContainer>
    );
};

export default Dashboard;