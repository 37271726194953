import { AddProdutosContainer, CategoriaSelectContainer, CategoriasBebidasContainer,EncerrarPedidoContainer, EscadaDePrecosContainer, InformacoesContainer, LimiteDeItensContainer, OutrasCategoriasContainer, PdfContainer, PedidoIncompletoContainer } from './styles';
import { ReactSVG } from 'react-svg';
import InformacoesSvg from '../../assets/informacoes.svg';
import EscadaDePrecosSvg from '../../assets/escada-preco.svg';
import EditarSvg from '../../assets/editar.svg';
import AddProdutosSvg from '../../assets/add-produtos.svg';
import CategoriaBebidasSvg from '../../assets/categoria-bebidas.svg';
import OutrasCategoriasSvg from '../../assets/outras-categorias.svg';
import VisualizarPdfSvg from '../../assets/visualizar-pdf.svg';
import ModalEditarPedidoInformacoes from '../Modals/ModalEditarPedidoInformacoes';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import api from '../../services/api';
import { useAuth } from '../../providers/Auth';
import { useNavigate } from 'react-router-dom';
import PedidoCategoriaBebidas from '../PedidoCategoriaBebidas';
import { toast } from 'react-toastify';
import PedidoCategoriaProdutos from '../PedidoCategoriaProdutos';
import ModalRemoverPedidoCategoria from '../Modals/ModalRemoverPedidoCategoria';
import PdfViewer from '../PdfViewer';
import ModalCancelarPedido from '../Modals/ModalCancelarPedido';
import ModalFinalizarPedido from '../Modals/ModalFinalizarPedido';
import Loading from '../Loading';
import ExcluirSvg from '../../assets/excluir.svg';
import EnviarAprovarSvg from '../../assets/enviar-aprovar.svg';
import ImportarSvg from '../../assets/importar.svg';
import ModalImportarPedidoProdutos from '../Modals/ModalImportarPedidoProdutos';
import AguardandoSvg from '../../assets/aguardando.svg';
import ModalEscadaPreco from '../Modals/ModalEscadaPreco';
import DownloadPedidoButton from '../DownloadPedidoButton';

const PedidoIncompleto = ({ pedido, buscarPedidoIncompleto, buscarPedidos}) => {

    const { user } = useAuth();
    const geoIdDoUsuarioLogado = user?.geo_id;

    const [modalEditarPedidoInformacoes, setModalEditarPedidoInformacoes] = useState(false);
    const [modalEscadaPrecos, setModalEscadaPrecos] = useState(false);

    const [aguardarPedido, setAguardarPedido] = useState(false)

    const [levaAtual, setLevaAtual] = useState('');
  
    const [pedidoBebidaCategorias, setPedidoBebidaCategorias] = useState([]);
    const [pedidoBebidas, setPedidoBebidas] = useState([]);

    const [pedidoProdutoCategorias, setPedidoProdutoCategorias] = useState([]);
    const [pedidoProdutos, setPedidoProdutos] = useState([]);

    const [categoriasBebidasOptions, setCategoriasBebidasOptions] = useState([]);
    const [categoriasBebidasValue, setCategoriasBebidasValue] = useState([]);
    const [categoriaBebidasLoading, setCategoriaBebidasLoading] = useState(false);

    const [outrasCategoriasValue, setOutrasCategoriasValue] = useState([]);
    const [outrasCategoriasInputValue, setOutrasCategoriasInputValue] = useState('');
    const [outrasCategoriasError, setOutrasCategoriasError] = useState('');
    const [outrasCategoriasLoading, setOutrasCategoriasLoading] = useState(false);

    const [removerPedidoBebidaCategoriaModal, setRemoverPedidoBebidaCategoriaModal] = useState(false);
    const [removerPedidoProdutoCategoriaModal, setRemoverPedidoProdutoCategoriaModal] = useState(false);

    const [limiteDeItens, setLimiteDeItens] = useState(0);
    const [limiteAtual, setLimiteAtual] = useState(0);

    const [importarPedidoProdutosModal, setImportarPedidoProdutosModal] = useState(false);
    const [cancelarPedidoModal, setCancelarPedidoModal] = useState(false);
    const [finalizarPedidoModal, setFinalizarPedidoModal] = useState(false);

    const [loading, setLoading] = useState(true);
    const [categoriaBebidasLoaded, setCategoriaBebidasLoaded] = useState(false);
    const [pedidoBebidaCategoriasLoaded, setPedidoBebidaCategoriasLoaded] = useState(false);
    const [pedidoProdutoCategoriasLoaded, setPedidoProdutoCategoriasLoaded] = useState(false);
    const [pedidoBebidasLoaded, setPedidoBebidasLoaded] = useState(false);
    const [pedidoProdutosLoaded, setPedidoProdutosLoaded] = useState(false);
    const [levaAtualLoaded, setLevaAtualLoaded] = useState(false);

    const { logout } = useAuth();
    const navigate = useNavigate();
    const MAX_CATEGORIA_LENGTH = 20;
    useEffect(() => {
        if (
            categoriaBebidasLoaded 
            &&
            pedidoBebidaCategoriasLoaded
            && 
            pedidoProdutoCategoriasLoaded
            && 
            pedidoBebidasLoaded
            && 
            pedidoProdutosLoaded
            &&
            levaAtualLoaded
        ){
            setLoading(false);
        };
    }, [levaAtualLoaded, categoriaBebidasLoaded, pedidoBebidaCategoriasLoaded, pedidoProdutoCategoriasLoaded, pedidoBebidasLoaded, pedidoProdutosLoaded]);
    
    // useEffect(() => {
    //     if (!loading && pedido.link) {
    //         window.location.href = '#pdf';
    //     };
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [loading]);

    const buscarPedidoBebidaCategorias = () => {
        setCategoriaBebidasLoading(true);
        api.get(`/pedido-bebida-categorias/${pedido.id}`)
            .then((response) => {
                const pedidoBebidaCategorias = response.data.data.categorias;
                setPedidoBebidaCategorias(pedidoBebidaCategorias);
                const options = [];
                pedidoBebidaCategorias.forEach((pedidoBebidaCategoria) => {
                    const categoria = categoriasBebidasOptions.find((categoria) => categoria.label === pedidoBebidaCategoria.nome);
                    if (categoria) {
                        categoria.pedido_bebida_categoria_id = pedidoBebidaCategoria.id;
                        categoria.posicao = pedidoBebidaCategoria.posicao;
                        categoria.ordem = pedidoBebidaCategoria.ordem;
                        options.push(categoria);
                    };
                });
                setCategoriasBebidasValue(options.sort((a, b) => Number(a.posicao) - Number(b.posicao)));
                setCategoriaBebidasLoading(false);
                setPedidoBebidaCategoriasLoaded(true);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                };
                setCategoriaBebidasLoading(false);
            });
    };

    const buscarPedidoProdutoCategorias = () => {
        setOutrasCategoriasLoading(true);
        api.get(`/pedido-produto-categorias/${pedido.id}`)
            .then((response) => {
                const pedidoProdutoCategorias = response.data.data.categorias;
                setPedidoProdutoCategorias(pedidoProdutoCategorias);
                const options = pedidoProdutoCategorias.map((pedidoProdutoCategoria) => {
                    return {
                        value: pedidoProdutoCategoria.id,
                        label: pedidoProdutoCategoria.nome,
                        pedido_produto_categoria_id: pedidoProdutoCategoria.id,
                        posicao: pedidoProdutoCategoria.posicao,
                        ordem: pedidoProdutoCategoria.ordem,
                    };
                });
                setOutrasCategoriasValue(options.sort((a, b) => Number(a.posicao) - Number(b.posicao)));
                setOutrasCategoriasLoading(false);
                setPedidoProdutoCategoriasLoaded(true);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                };
                setOutrasCategoriasLoading(false);
            });
    };

    const buscarPedidoBebidas = () => {
        setPedidoBebidasLoaded(false);
        api.get(`/pedido-bebidas/${pedido.id}`)
            .then((response) => {
                const pedidoBebidas = response.data.data.bebidas;
                setPedidoBebidas(pedidoBebidas);
                setPedidoBebidasLoaded(true);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                };
            });
    };

    const buscarPedidoProdutos = () => {
        setPedidoProdutosLoaded(false);
        api.get(`/pedido-produtos/${pedido.id}`)
            .then((response) => {
                const pedidoProdutos = response.data.data.produtos;
                setPedidoProdutos(pedidoProdutos);
                setPedidoProdutosLoaded(true);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                };
            });
    };

    const buscarLeva = () => {
        api.get(`/levas`)
            .then((response) => {
                const [leva] = response.data.data.levas;
                if (!leva.data_encerramento) {
                    setLevaAtual(leva);
                } else {
                    setLevaAtual('');
                };
                setLevaAtualLoaded(true);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                };
                if (error.response?.status === 404){
                    navigate('/pedidos');
                };
            });
    };

    useEffect(() => {
        const buscarBebidaCategorias = () => {
            api.get('/categorias-bebidas')
                .then((response) => {
                    const categoriasGeo = response.data.data.categorias.filter((categoria) => categoria.geo_id === pedido.geo_id);
                    const options = categoriasGeo.map((categoria) => {
                        return {
                            value: categoria.id,
                            label: categoria.nome,
                        };
                    });
                    setCategoriasBebidasOptions(options);
                    setCategoriaBebidasLoaded(true);
                }).catch((error) => {
                    if (error.response?.status === 401){
                        logout();
                        navigate('/');
                    };
                });
        };
        if (pedido) {
            buscarBebidaCategorias();
            buscarPedidoProdutoCategorias();
            buscarPedidoBebidas();
            buscarPedidoProdutos();
            buscarLeva();
        };
        if (pedido.template) {
            if (pedido.template === 'brahma' || pedido.template === 'brahma duplo malte') {
                if (pedido.tipo === 'frenteVerso') {
                    setLimiteDeItens(128);
                };
                if (pedido.tipo === 'verso') {
                    setLimiteDeItens(64);
                };
                if (pedido.tipo === 'paginaTodaSemCapa') {
                    setLimiteDeItens(32);
                };
                if (pedido.tipo === 'misto' || pedido.tipo === 'mistoPaginaToda') {
                    setLimiteDeItens(94);
                };
            };
            if (pedido.template === 'spaten') {
                if (pedido.tipo === 'frenteVerso') {
                    setLimiteDeItens(120);
                };
                if (pedido.tipo === 'verso') {
                    setLimiteDeItens(60);
                };
                if (pedido.tipo === 'paginaTodaSemCapa') {
                    setLimiteDeItens(32);
                };
                if (pedido.tipo === 'misto' || pedido.tipo === 'mistoPaginaToda') {
                    setLimiteDeItens(96);
                };
            };
            if (pedido.template === 'budweiser') {
                if (pedido.tipo === 'frenteVerso') {
                    setLimiteDeItens(116);
                };
                if (pedido.tipo === 'verso') {
                    setLimiteDeItens(58);
                };
                if (pedido.tipo === 'paginaTodaSemCapa') {
                    setLimiteDeItens(28);
                };
                if (pedido.tipo === 'misto' || pedido.tipo === 'mistoPaginaToda') {
                    setLimiteDeItens(86);
                };
            };
            if(pedido.template === 'original'){
                if (pedido.tipo === 'frenteVerso') {
                    setLimiteDeItens(120);
                };
                if (pedido.tipo === 'verso') {
                    setLimiteDeItens(60);
                };
                if (pedido.tipo === 'paginaTodaSemCapa') {
                    setLimiteDeItens(30);
                };
                if (pedido.tipo === 'misto' || pedido.tipo === 'mistoPaginaToda') {
                    setLimiteDeItens(90);
                };
            };
            if(pedido.template === 'stella'){
                if (pedido.tipo === 'frenteVerso') {
                    setLimiteDeItens(140);
                };
                if (pedido.tipo === 'verso') {
                    setLimiteDeItens(70);
                };
                if (pedido.tipo === 'paginaTodaSemCapa') {
                    setLimiteDeItens(35);
                };
                if (pedido.tipo === 'misto' || pedido.tipo === 'mistoPaginaToda') {
                    setLimiteDeItens(105);
                };
            };
            if(pedido.template === 'bohemia'){
                if (pedido.tipo === 'frenteVerso') {
                    setLimiteDeItens(120);
                };
                if (pedido.tipo === 'verso') {
                    setLimiteDeItens(60);
                };
                if (pedido.tipo === 'paginaTodaSemCapa') {
                    setLimiteDeItens(30);
                };
                if (pedido.tipo === 'misto' || pedido.tipo === 'mistoPaginaToda') {
                    setLimiteDeItens(90);
                };
            };
            if(pedido.template === 'skol'){
                if (pedido.tipo === 'frenteVerso') {
                    setLimiteDeItens(120);
                };
                if (pedido.tipo === 'verso') {
                    setLimiteDeItens(60);
                };
                if (pedido.tipo === 'paginaTodaSemCapa') {
                    setLimiteDeItens(30);
                };
                if (pedido.tipo === 'misto' || pedido.tipo === 'mistoPaginaToda') {
                    setLimiteDeItens(90);
                };
            };
            if(pedido.template === 'corona'){
                if (pedido.tipo === 'frenteVerso') {
                    setLimiteDeItens(112);
                };
                if (pedido.tipo === 'verso') {
                    setLimiteDeItens(56);
                };
                if (pedido.tipo === 'paginaTodaSemCapa') {
                    setLimiteDeItens(28);
                };
                if (pedido.tipo === 'misto' || pedido.tipo === 'mistoPaginaToda') {
                    setLimiteDeItens(84);
                };
            };
            if(pedido.template === 'guarana zero'){
                if (pedido.tipo === 'frenteVerso') {
                    setLimiteDeItens(120);
                };
                if (pedido.tipo === 'verso') {
                    setLimiteDeItens(60);
                };
                if (pedido.tipo === 'paginaTodaSemCapa') {
                    setLimiteDeItens(30);
                };
                if (pedido.tipo === 'misto' || pedido.tipo === 'mistoPaginaToda') {
                    setLimiteDeItens(90);
                };
            };
        };
    }, [pedido]);

    useEffect(() => {
        if (categoriaBebidasLoaded) {
            buscarPedidoBebidaCategorias();
        };
    }, [categoriaBebidasLoaded]);

    useEffect(() => {
        if (!loading) {
            const bebidaCategoriasSub = pedidoBebidaCategorias.length * 2;
            const bebidasSub = pedidoBebidas.length;
            const outrasCategoriasSub = pedidoProdutoCategorias.length * 2;
            const produtosSub = pedidoProdutos.length;
            const total = bebidaCategoriasSub + bebidasSub + outrasCategoriasSub + produtosSub;
            const limite = limiteDeItens - total;
            setLimiteAtual(limite);
        }
    }, [loading,pedidoBebidaCategorias, pedidoProdutoCategorias, pedidoBebidas, pedidoProdutos]);

    //ADICIONA CATEGORIA DE BEBIDAS AO PEDIDO
    const handleAdicionaPedidoBebidaCategoria = (item, posicao) => {
        setCategoriaBebidasLoading(true);
        const dados = {
            nome: item.label,
            posicao,
        };
        api.post(`/pedido-bebida-categorias/${pedido.id}`, dados)
            .then((response) => {
                buscarPedidoBebidaCategorias();
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                    return;
                };
                toast.error('Não foi possível adicionar a categoria. Tente novamente mais tarde.')
            });
    };

    //REMOVE CATEGORIA DE BEBIDAS AO PEDIDO
    const handleRemovePedidoBebidaCategoria = (item) => {
        setCategoriaBebidasLoading(true);
        const pedidoCategoriaId = pedidoBebidaCategorias.find((pedidoCategoria) => {
            return pedidoCategoria.nome === item.label;
        }).id;
        api.delete(`/pedido-bebida-categorias/${pedido.id}`, {data: {categoria_id: pedidoCategoriaId}})
            .then((response) => {
                buscarPedidoBebidaCategorias();
                buscarPedidoBebidas();
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                    return;
                };
                setCategoriaBebidasLoading(false);
                toast.error('Não foi possível remover a categoria. Tente novamente mais tarde.')    
            });
    };

    //ADICIONAR CATEGORIA AO PEDIDO
    const handleAdicionaPedidoProdutoCategoria = (item, posicao) => {
        setOutrasCategoriasLoading(true);
        const dados = {
            nome: item.label,
            posicao,
        };
        api.post(`/pedido-produto-categorias/${pedido.id}`, dados)
            .then((response) => {
                buscarPedidoProdutoCategorias();
                buscarPedidoBebidas();
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                    return;
                };
                toast.error('Não foi possível adicionar a categoria. Tente novamente mais tarde.');
            });
    };

    //REMOVE CATEGORIA DO PEDIDO
    const handleRemovePedidoProdutoCategoria = (item) => {
        setOutrasCategoriasLoading(true);
        api.delete(`/pedido-produto-categorias/${pedido.id}`, {data: {categoria_id: item.value}})
            .then((response) => {
                buscarPedidoProdutoCategorias();
                buscarPedidoProdutos();
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                    return;
                };
                toast.error('Não foi possível remover a categoria. Tente novamente mais tarde.');
            });
    };

    //GERAR CARDARDAPIO
    const handleGeraPdf = async (noReload) => {
        if (limiteAtual < 0) {
            toast.error('O limite de itens foi excedido.');
            return 'O limite de itens foi excedido.';
        };
        const pedidoBebidasCategoriasOrdenadas = pedidoBebidaCategorias.sort((a, b) => Number(a.posicao) - Number(b.posicao));
        const pdfBebidas = [];
        for (const categoria of pedidoBebidasCategoriasOrdenadas) {
            const bebidas = pedidoBebidas.filter((bebida) => {
                return bebida.categoria_id === categoria.id;
            }).sort((a, b) => {
                const precoA = Number(a.preco.replace(',', '.'));
                const precoB = Number(b.preco.replace(',', '.'));
                if (categoria.ordem === 'crescente') {
                    if (precoA > precoB) {
                        return 1
                    } else if (precoA < precoB) {
                        return -1
                    }
                };
                if (categoria.ordem === 'decrescente') {
                    if (precoA < precoB) {
                        return 1
                    } else if (precoA > precoB) {
                        return -1
                    }
                };
                if (categoria.ordem !== 'crescente' && categoria.ordem !== 'decrescente') {
                    return Number(a.ordem) - Number(b.ordem)
                };
            }).map((bebida) => {
                const dados = {};
                if(bebida.preco === '3 por 10,00' || bebida.preco === '4 por 12,00'){
                    dados.preco = bebida.preco;
                } else {
                    dados.preco = 'R$' + bebida.preco;
                };
                if (categoria.nome.includes(bebida.volume)) {
                    dados.texto = bebida.nome;
                } else {
                    dados.texto = `${bebida.nome} ${bebida.volume}`;
                };
                return dados;
            });

            if (!bebidas.length) {
                toast.error(`Adicione pelo menos um produto na categoria ${categoria.nome}`);
                return `Adicione pelo menos um produto na categoria ${categoria.nome}`;
            };

            pdfBebidas.push({
                categoria: categoria.nome,
                produtos: bebidas,
            });
        };
        const pedidoProdutosCategoriasOrdenadas = pedidoProdutoCategorias.sort((a, b) => Number(a.posicao) - Number(b.posicao));
        const pdfProdutos = [];
        for (const categoria of pedidoProdutosCategoriasOrdenadas) {
            const produtos = pedidoProdutos.filter((produto) => {
                return produto.categoria_id === categoria.id;
            }).sort((a, b) => {
                const precoA = Number(a.preco.replace(',', '.'));
                const precoB = Number(b.preco.replace(',', '.'));
                if (categoria.ordem === 'crescente') {
                    if (precoA > precoB) {
                        return 1
                    } else {
                        return -1
                    }
                };
                if (categoria.ordem === 'decrescente') {
                    if (precoA < precoB) {
                        return 1
                    } else {
                        return -1
                    }
                };
                if (categoria.ordem !== 'crescente' && categoria.ordem !== 'decrescente') {
                    return Number(a.ordem) - Number(b.ordem)
                };
            }).map((produto) => {
                return {
                    texto: produto.nome + ' ' + produto.volume,
                    preco: 'R$' + produto.preco,
                };
            });

            if (!produtos.length) {
                toast.error(`Adicione pelo menos um produto na categoria ${categoria.nome}`);
                return `Adicione pelo menos um produto na categoria ${categoria.nome}`;
            };
            
            pdfProdutos.push({
                categoria: categoria.nome,
                produtos: produtos,
            });
        };

        const dadosPdf = {
            template: pedido.template,
            tituloPdf: pedido.titulo,
            pedidoId: pedido.id,
            itens: pdfBebidas.concat(pdfProdutos),
            options: {},
        };

        if (pedido.tipo === 'verso') {
            dadosPdf.options.capa = true;
        };
        if (pedido.tipo === 'paginaTodaSemCapa') {
            dadosPdf.options.paginaToda = true;
        };
        if (pedido.tipo === 'misto') {
            dadosPdf.options.misto = true;
        };
        if (pedido.tipo === 'mistoPaginaToda') {
            dadosPdf.options.misto = true;
            dadosPdf.options.paginaToda = true;
        };
        
        setLoading(true);
        try {
            await api.post('/pdf', dadosPdf)
            if (noReload) return new Promise((resolve, reject) => {
                setTimeout(() => {
                  resolve();
                }, 5000);
              });
            toast.success("Gerando pdf...", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
            });
            setTimeout(() => {
                buscarPedidoIncompleto();
                navigate('#pdf')
            }, 5000);
        } catch (error) {
            if (error.response?.status === 401){
                logout();
                navigate('/');
                return;
            };
            if (error.response?.status === 400) {
                if (error.response.data) {
                    toast.error(error.response.data.message + ' item: ' + error.response.data.data.texto, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                    });
                };
                buscarPedidoIncompleto();
                return;
            };
            toast.error('Não foi possível gerar o pdf. Tente novamente mais tarde.');
        };
    };

    return (
        <PedidoIncompletoContainer>
            <InformacoesContainer>
                <h2>
                    <ReactSVG src={InformacoesSvg} /> Informações
                </h2>
                <div className='informacoes'>
                    <div className='item'>
                        <h3>Operação</h3>
                        <p>{pedido.nome_operacao}</p>
                    </div>
                    <div className='item'>
                        <h3>PDV</h3>
                        <p>{pedido.ponto_de_venda}</p>
                    </div>
                    <div className='item'>
                        <h3>Título</h3>
                        <p>{pedido.titulo}</p>
                    </div>
                    <div className='item'>
                        <h3>Tipo</h3>
                        <p>
                            {pedido.tipo === 'verso' && 'Frente e Verso C/ Capa'}
                            {pedido.tipo === 'frenteVerso' && 'Frente e Verso S/ Capa'}
                            {pedido.tipo === "paginaTodaSemCapa" && "Frente e Verso - Coluna Unica"}
                            {pedido.tipo === "misto" && "Frente e Verso Misto - S/ Capa x Coluna Unica"}
                            {pedido.tipo === "mistoPaginaToda" && "Frente e Verso Misto - Coluna Unica x S/ Capa"}
                        </p>
                    </div>
                    { pedido.impresso === 1  &&( <div className='item'>
                        <h3>Quantidade</h3>
                        <p>
                            {pedido.quantidade} {pedido.quantidade > 1 ? 'unidades' : 'unidade'}
                        </p>
                    </div>)}
                   
                     { pedido.qrcode === 1  &&(   <div className='item'>
                        <h3>Quant. QR Code</h3>
                        <p>
                            {pedido.quantidade_qrcode} {pedido.quantidade_qrcode > 1 ? 'unidades' : 'unidade'} 
                        </p>
                    </div>)}
                   
                    <div className='item'>
                        <h3>Marca</h3>
                        <p>{pedido.template}</p>
                    </div>
                    <div className='item'>
                        <h3>Cardápio</h3>
                        <p>
                            {`${pedido.impresso ? 'Impresso' : ''} ${pedido.impresso && pedido.qrcode ? 'e' : ''} ${pedido.qrcode ? 'QR Code' : ''}`}
                            {!pedido.impresso && !pedido.qrcode && 'Digital'}
                        </p>
                    </div>
                    <button className='editar' onClick={() => setModalEditarPedidoInformacoes(true)}>
                        <ReactSVG src={EditarSvg}/> editar informações
                    </button>
                </div>
            </InformacoesContainer>
            {loading ? (
                <Loading />
            ) : (
                <>
                {["admin"].includes(user.permissao) && <EscadaDePrecosContainer>
                    <h2>
                        <ReactSVG src={EscadaDePrecosSvg} /> Escada de preços <button onClick={() => setModalEscadaPrecos(true)}>{'visualizar >'}</button>
                    </h2>
                </EscadaDePrecosContainer>}
                <AddProdutosContainer>
                    <h2>
                        <ReactSVG src={AddProdutosSvg} /> 
                        Add produtos
                        {(!pedidoBebidas.length && !pedidoProdutos.length) && (
                            <button className='importar' onClick={() => setImportarPedidoProdutosModal(true)}>
                                <ReactSVG src={ImportarSvg}/> importar produtos
                            </button>
                        )}
                    </h2>
                    <CategoriaSelectContainer>
                        <label>
                            <ReactSVG src={CategoriaBebidasSvg} /> Categoria Bebidas
                        </label>
                        <Select 
                            className='categorias-select'
                            classNamePrefix='react-select'
                            options={categoriasBebidasOptions}
                            value={categoriasBebidasValue}
                            onChange={(value, item) => {
                                if (item.action === 'select-option') {
                                    const ultimaCategoria = categoriasBebidasValue.at(-1)
                                    const posicao = ultimaCategoria ? Number(ultimaCategoria.posicao)+1 : 1;
                                    handleAdicionaPedidoBebidaCategoria(item.option, posicao);
                                };
                                if (item.action === 'remove-value') {
                                    const pedidoCategoriaBebidas = pedidoBebidas.filter((pedidoBebida) => {
                                        return pedidoBebida.categoria === item.removedValue.label;
                                    });
                                    if (pedidoCategoriaBebidas.length) {
                                        setRemoverPedidoBebidaCategoriaModal(item.removedValue);
                                    } else {
                                        handleRemovePedidoBebidaCategoria(item.removedValue);
                                    }
                                };
                                // setCategoriasBebidasValue(value)
                            }}
                            placeholder='Selecione uma categoria'
                            isMulti
                            isLoading={categoriaBebidasLoading}
                        />
                    </CategoriaSelectContainer>
                    <CategoriaSelectContainer>
                        <label>
                            <ReactSVG src={OutrasCategoriasSvg}/> Outras categorias
                        </label>
                        <Select
                            className='outras-categorias-select'
                            classNamePrefix='react-select'
                            options={[]}
                            value={outrasCategoriasValue}
                            inputValue={outrasCategoriasInputValue}
                            onInputChange={(value) => {                            
                                setOutrasCategoriasError('');
                                setOutrasCategoriasInputValue(value)
                            }}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter' && outrasCategoriasInputValue) {
                                    const isOptionAlreadySelected = outrasCategoriasValue.some((option) => option.label === event.target.value);
                                    if (outrasCategoriasInputValue.length > MAX_CATEGORIA_LENGTH) {
                                        setOutrasCategoriasError(`Limite de caracteres excedido para a categoria. Máximo ${MAX_CATEGORIA_LENGTH} caracteres.`);
                                        return;
                                    }
                                   
                                    if (isOptionAlreadySelected) {
                                        setOutrasCategoriasError(`A categoria "${event.target.value}" já foi adicionada.`);
                                        return;
                                    };

                                    const newOption = { value: outrasCategoriasInputValue, label: outrasCategoriasInputValue };
                                    // setOutrasCategoriasValue([...outrasCategoriasValue, newOption]);
                                    const ultimaCategoria = outrasCategoriasValue.at(-1);
                                    const posicao = ultimaCategoria ? Number(ultimaCategoria.posicao)+1 : 1;
                                    handleAdicionaPedidoProdutoCategoria(newOption, posicao);
                                    setOutrasCategoriasInputValue('');
                                }
                            }}
                            onChange={(value, item) => {
                                if (item.action === 'clear') {
                                    // setOutrasCategoriasValue([]);
                                };
                                if (item.action === 'remove-value') {
                                    const pedidoCategoriaProdutos = pedidoProdutos.filter((pedidoProduto) => {
                                        return pedidoProduto.categoria === item.removedValue.label;
                                    });
                                    if (pedidoCategoriaProdutos.length) {
                                        setRemoverPedidoProdutoCategoriaModal(item.removedValue);
                                    } else {
                                        handleRemovePedidoProdutoCategoria(item.removedValue);
                                    };
                                    // const updatedOptions = outrasCategoriasValue.filter(option => option !== item.removedValue);
                                    // setOutrasCategoriasValue(updatedOptions);
                                };
                            }}
                            isLoading={outrasCategoriasLoading}
                            isClearable
                            isMulti
                            placeholder='Digite o nome e aperte em "enter" para criar uma nova categoria'
                        />
                        {outrasCategoriasError && <span className='input-error'>{outrasCategoriasError}</span>}
                    </CategoriaSelectContainer>
                    <LimiteDeItensContainer>
                        Limite de itens no cardápio: {limiteAtual}
                    </LimiteDeItensContainer>
                    {categoriaBebidasLoading || outrasCategoriasLoading ? (
                        <Loading />
                    ) : (
                        <>
                        <CategoriasBebidasContainer>
                            {categoriasBebidasValue.map((categoria, index) => (
                                <PedidoCategoriaBebidas 
                                    key={categoria.value}
                                    index={index}
                                    categoria={categoria}
                                    pedido={pedido}
                                    categoriasBebidasValue={categoriasBebidasValue}
                                    buscarPedidoBebidaCategorias={buscarPedidoBebidaCategorias}
                                    setCategoriaBebidasLoading={setCategoriaBebidasLoading}
                                    pedidoBebidas={pedidoBebidas.filter((pedidoBebida) => pedidoBebida.categoria === categoria.label)}
                                    buscarPedidoBebidas={buscarPedidoBebidas}
                                    pedidoBebidasLoaded={pedidoBebidasLoaded}
                                />
                            ))}
                        </CategoriasBebidasContainer>
                        <OutrasCategoriasContainer>
                            {outrasCategoriasValue.map((categoria, index) => (
                                <PedidoCategoriaProdutos
                                    key={categoria.value}
                                    index={index}
                                    categoria={categoria}
                                    pedido={pedido}
                                    outrasCategoriasValue={outrasCategoriasValue}
                                    buscarPedidoProdutoCategorias={buscarPedidoProdutoCategorias}
                                    setOutrasCategoriasLoading={setOutrasCategoriasLoading}
                                    pedidoProdutos={pedidoProdutos.filter((pedidoProduto) => pedidoProduto.categoria === categoria.label)}
                                    buscarPedidoProdutos={buscarPedidoProdutos}
                                    pedidoProdutosLoaded={pedidoProdutosLoaded}
                                />
                            ))}
                        </OutrasCategoriasContainer>
                        </>
                    )}
                    {(pedidoBebidas.length || pedidoProdutos.length) && (
                        <div className='salvar'>
                            <button onClick={async () => await handleGeraPdf()}>
                                Gerar pré-visualização do cardápio
                            </button>
                        </div>
                    )}
                </AddProdutosContainer>
                <PdfContainer id='pdf'>
                    {pedido.link && (
                        <>
                        <h2>
                            <ReactSVG src={VisualizarPdfSvg} /> Visualização de PDF
                        </h2>
                        <PdfViewer fileUrl={pedido.link} pedido={pedido}/>
                        </>
                    )}
                    <EncerrarPedidoContainer>
                        {pedido.status === 'INCOMPLETO' && (
                            <button 
                                className='cancelar' 
                                onClick={() => setCancelarPedidoModal(true)}
                                >
                                <ReactSVG src={ExcluirSvg}/> Cancelar Pedido
                            </button>
                        )}
                       
                        {(pedido.link && (
                        (["admin", "gv", "gc"].includes(user.permissao) && geoIdDoUsuarioLogado !== 3) ||
                        (["admin", "gc"].includes(user.permissao) && geoIdDoUsuarioLogado === 3)
                        )) && (
                            <button 
                                className='finalizar' 
                                onClick={() => {
                                    if (!levaAtual) return toast.error('Nenhuma leva em andamento no momento.');
                                    setAguardarPedido(false)
                                    setFinalizarPedidoModal(true)
                                }}
                                disabled={!pedido.link}
                                >
                                <ReactSVG src={EnviarAprovarSvg} /> Enviar para aprovação
                                
                            </button>
                            
                        )}
                        
                
                        {(user?.geo_id === 3 || user?.geo_id === 1) && (
                           
                            <button 
                                className='aguardando' 
                                onClick={() => {
                                    if (!levaAtual) return toast.error('Nenhuma leva em andamento no momento.');
                                    setAguardarPedido(true)
                                    setFinalizarPedidoModal(true)
                                }}
                                disabled={!pedido.link}
                                >
                                <ReactSVG src={AguardandoSvg} /> Enviar para aguardando
                            </button>
                           
                        )}
                        {(user.permissao !== 'rn' && (user.geo_id === 1) ) && (
                        <DownloadPedidoButton
                            pedido={pedido}
                            buscarPedidos={buscarPedidoIncompleto}
                            
                        />)}
                        </EncerrarPedidoContainer>
                </PdfContainer>
                </>
            )}
            {modalEditarPedidoInformacoes && (
                <ModalEditarPedidoInformacoes setModalOpened={setModalEditarPedidoInformacoes} pedido={pedido} buscarPedidoIncompleto={buscarPedidoIncompleto} />
            )}
            {removerPedidoBebidaCategoriaModal && (
                <ModalRemoverPedidoCategoria setModalOpened={setRemoverPedidoBebidaCategoriaModal} categoria={removerPedidoBebidaCategoriaModal} handleRemoverCategoria={handleRemovePedidoBebidaCategoria}/>
            )}
            {removerPedidoProdutoCategoriaModal && (
                <ModalRemoverPedidoCategoria setModalOpened={setRemoverPedidoProdutoCategoriaModal} categoria={removerPedidoProdutoCategoriaModal} handleRemoverCategoria={handleRemovePedidoProdutoCategoria}/>
            )}
            {cancelarPedidoModal && (
                <ModalCancelarPedido setModalOpened={setCancelarPedidoModal} buscarPedido={buscarPedidoIncompleto}/>
            )}
            {finalizarPedidoModal && (
                <ModalFinalizarPedido setModalOpened={setFinalizarPedidoModal} pedido={pedido} levaAtual={levaAtual} handleGeraPdf={handleGeraPdf} aguardarPedido={aguardarPedido}/>
            )}
            {importarPedidoProdutosModal && (
                <ModalImportarPedidoProdutos setModalOpened={setImportarPedidoProdutosModal} pedido={pedido} buscarPedido={buscarPedidoIncompleto}/>
            )}
            {modalEscadaPrecos && (
                <ModalEscadaPreco setModalOpened={setModalEscadaPrecos} />
            )}
        </PedidoIncompletoContainer>
    )
};

export default PedidoIncompleto;