import { AddDegrau, AdminEscadaContainer, BotaoAdicionarDegrau, BotaoAdicionarDegrauBaixo, BotaoRemoverDegrau, ContentContainer, DegrausContainer } from './styles';
import { ReactSVG } from 'react-svg';
import { useState, useEffect } from 'react';
import { useAuth } from '../../providers/Auth';
import EscadaPrecoSvg from '../../assets/escada-preco.svg';
import MaisCaroSvg from '../../assets/mais-caro.svg';
import MaisBaratoSvg from '../../assets/mais-barato.svg';
import Loading from '../Loading';
import api from '../../services/api';
import { useNavigate } from 'react-router-dom';
import { range } from '../../utils/range';
import EscadaDegrau from '../EscadaDegrau';
import { toast } from 'react-toastify';

const AdminEscada = () => {
    const [escadaPreco, setEscadaPreco] = useState('');
    const [degraus, setDegraus] = useState([]);
    const [escadaBebidas, setEscadaBebidas] = useState([]);
    
    const { user, logout } = useAuth();
    const navigate = useNavigate();
    
    const [escadaPrecoLoaded, setEscadaPrecoLoaded] = useState(false);
    const [escadaBebidasLoaded, setEscadaBebidasLoaded] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (escadaPrecoLoaded && escadaBebidasLoaded) {
            setLoading(false);
        } else {
            setLoading(true);
        };
    }, [escadaPrecoLoaded, escadaBebidasLoaded]);

    const buscarEscadaPreco = () => {
        setEscadaPrecoLoaded(false);
        api.get('/escadas-precos')
            .then((response) => {
                const [escada] = response.data.data.escadas;
                setDegraus(range(escada.degraus_cima, escada.degraus_baixo));
                setEscadaPreco(escada);
                setEscadaPrecoLoaded(true);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                    return;
                };
                toast.error('Erro ao buscar escada de preço.');
            });
    };

    useEffect(() => {
        buscarEscadaPreco();
    }, []);

    const buscarEscadaBebidas = () => {
        setEscadaBebidasLoaded(false);
        api.get(`/escada-bebida-volumes/${escadaPreco.id}`)
            .then((response) => {
                const escadaBebidasOrdenadas = response.data.data.volumes.sort((a, b) => {
                    if (a.posicao > b.posicao) {
                        return 1
                    } else if (a.posicao < b.posicao) {
                        return -1
                    }
                });
                setEscadaBebidas(escadaBebidasOrdenadas);
                setEscadaBebidasLoaded(true);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                    return;
                };
                toast.error('Erro ao buscar bebidas da escada de preço.');
            });
    };

    useEffect(() => {
        if (escadaPreco) {
            buscarEscadaBebidas()
        };
    }, [escadaPreco]);

    const handleAdicionaDegrauCima = () => {
        const dados = {
            degraus_cima: String(escadaPreco.degraus_cima - 1)
        };
        api.patch(`/escadas-precos/${escadaPreco.id}`, dados)
            .then((response) => {
                buscarEscadaPreco();
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                    return;
                };
                toast.error('Falha ao adicionar degrau.')
            });
    }; 

    const handleRemoverDegrauCima = () => {
        const dados = {
            degraus_cima: String(escadaPreco.degraus_cima + 1)
        };
        api.patch(`/escadas-precos/${escadaPreco.id}`, dados)
            .then((response) => {
                buscarEscadaPreco();
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                    return;
                };
                toast.error('Falha ao adicionar degrau.')
            });
    }; 

    const handleAdicionaDegrauBaixo = () => {
        const dados = {
            degraus_baixo: String(escadaPreco.degraus_baixo + 1)
        };
        api.patch(`/escadas-precos/${escadaPreco.id}`, dados)
            .then((response) => {
                buscarEscadaPreco();
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                    return;
                };
                toast.error('Falha ao adicionar degrau.')
            });
    };
    const handleRemoverDegrauBaixo = () => {
        const dados = {
            degraus_baixo: String(escadaPreco.degraus_baixo - 1)
        };
        api.patch(`/escadas-precos/${escadaPreco.id}`, dados)
            .then((response) => {
                buscarEscadaPreco();
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                    return;
                };
                toast.error('Falha ao adicionar degrau.')
            });
    };
    
    
    return (
        <AdminEscadaContainer>
            <div className="header">
                <ReactSVG src={EscadaPrecoSvg} /> <h1>Escada de preços</h1>
            </div>
            {loading ? (
                <Loading />
            ) : (
                <ContentContainer>
                    <div className='caption'>
                        <ReactSVG src={MaisCaroSvg} /> <p>Bebidas <b>mais caras</b> acima</p>
                    </div>
                    <AddDegrau>
                    <BotaoAdicionarDegrau onClick={handleAdicionaDegrauCima}>
                        + adicionar novo degrau de comparação
                    </BotaoAdicionarDegrau>
                    <BotaoRemoverDegrau onClick={handleRemoverDegrauCima}>
                        - remover primeiro degrau de comparação
                    </BotaoRemoverDegrau>
                    </AddDegrau>
                    <DegrausContainer>
                        {degraus.map((degrau) => (
                            <EscadaDegrau 
                                key={degrau} 
                                degrau={degrau} 
                                escadaPreco={escadaPreco} 
                                escadaBebidas={escadaBebidas} 
                                buscarEscadaBebidas={buscarEscadaBebidas} 
                                visualizar={user.permissao === 'admin' ? false : true}
                            />
                        ))}
                    </DegrausContainer>
                    <AddDegrau>
                    <BotaoAdicionarDegrau onClick={handleAdicionaDegrauBaixo}>
                        + adicionar novo degrau de comparação
                    </BotaoAdicionarDegrau>
                    <BotaoRemoverDegrau onClick={handleRemoverDegrauBaixo}>
                        - remover ultimo degrau de comparação
                    </BotaoRemoverDegrau>
                    </AddDegrau>
                    
                    <div className='caption'>
                        <ReactSVG src={MaisBaratoSvg} /> <p>Bebidas <b>mais baratas</b> abaixo</p>
                    </div>
                </ContentContainer>
            )}
        </AdminEscadaContainer>
    );
};

export default AdminEscada;