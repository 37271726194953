import { ArrowAbsoluteBottom, ArrowAbsoluteTop, EscadaBebidaContainer, ExcluirBebidaVolumeButton, ExibirMaiorButton, ExibirMenorButton } from './styles';
import FecharSvg from '../../assets/fechar.svg'; 
import { ReactSVG } from 'react-svg';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { useAuth } from '../../providers/Auth';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';

const EscadaBebida = ({ 
    bebida, 
    setBebidaArrastada, 
    setBebidaSelecionada, 
    buscarEscadaBebidas, 
    exibirComparadoresMaior,
    exibirComparadoresMenor,
    setExibirComparadoresMaior, 
    setExibirComparadoresMenor, 
    setComparadoresMaior, 
    setComparadoresMenor,
    bebidaComparadorAtiva,
    setBebidaComparadorAtiva,
    visualizar
}) => {
    const [comparadoresMaiorQuantidade, setComparadoresMaiorQuantidade] = useState([]);
    const [comparadoresMenorQuantidade, setComparadoresMenorQuantidade] = useState([]);

    const [arrowAbsoluteTop, setArrowAbsoluteTop] = useState(false);
    const [arrowAbsoluteBottom, setArrowAbsoluteBottom] = useState(false);
        
    const { user, logout } = useAuth();
    const navigate = useNavigate();

    const buscarBebidaComparadores = () => {
        api.get(`/escada-bebida-volumes-comparador/${bebida.id}`)
            .then((response) => {
                const comparadores = response.data.data.comparadores;
                const maior = [];
                const menor = [];
                comparadores.forEach((elem) => {
                    if (elem.escada_bebida_volume_pai_id === bebida.id) {
                        if (elem.maior) {
                            menor.push(elem);
                        } else {
                            maior.push(elem);
                        }
                    };
                    if (elem.escada_bebida_volume_filho_id === bebida.id) {
                        if (elem.maior) {
                            maior.push(elem);
                        } else {
                            menor.push(elem);
                        }
                    };     
                });
                setComparadoresMaiorQuantidade(maior);
                setComparadoresMenorQuantidade(menor);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                    return;
                };
                toast.error('Erro ao buscar bebida comparadores.');
            })
    };

    useEffect(() => {
        buscarBebidaComparadores();
    }, []);
    
    const handleOnDrag = (e, item) => {
        setBebidaArrastada(item);
    };

    const handleOnDrop = (e) => {
        setBebidaSelecionada(bebida)
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    }

    const handleExcluirEscadaBebida = () => {
        api.delete(`/escada-bebida-volumes/${bebida.id}`)
            .then((response) => {
                buscarEscadaBebidas();
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                    return;
                };
                toast.error('Erro ao remover bebida.');
            });
    };

    useEffect(() => {
        if (bebidaComparadorAtiva !== bebida) {
            setArrowAbsoluteTop(false);
            setArrowAbsoluteBottom(false);
        }
    },[bebidaComparadorAtiva]);

    return (
        <EscadaBebidaContainer
            draggable
            onDragStart={(e) => handleOnDrag(e, bebida)}
            onDrop={handleOnDrop}
            onDragOver={handleDragOver}
        >
            {!visualizar && (
                <ExcluirBebidaVolumeButton onClick={handleExcluirEscadaBebida}>
                    <ReactSVG src={FecharSvg} />
                </ExcluirBebidaVolumeButton>
            )}
            {(!visualizar || (visualizar && comparadoresMaiorQuantidade.length)) && (
                <ExibirMaiorButton 
                    onClick={() => {
                        setBebidaComparadorAtiva(bebida);
                        setComparadoresMaior(comparadoresMaiorQuantidade);
                        if (bebidaComparadorAtiva === bebida) {
                            setArrowAbsoluteTop(!arrowAbsoluteTop);
                            setExibirComparadoresMaior(!exibirComparadoresMaior);
                        } else {
                            setExibirComparadoresMenor(false);
                            setArrowAbsoluteTop(true);
                            setExibirComparadoresMaior(true);
                        }
                    }}
                >
                    {comparadoresMaiorQuantidade.length}
                </ExibirMaiorButton>
            )}
            {(arrowAbsoluteTop && bebidaComparadorAtiva === bebida)&& (
                <ArrowAbsoluteTop />
            )}
            {(!visualizar || (visualizar && comparadoresMenorQuantidade.length)) && (
                <ExibirMenorButton
                    onClick={() => {
                        setBebidaComparadorAtiva(bebida);
                        setComparadoresMenor(comparadoresMenorQuantidade);
                        if (bebidaComparadorAtiva === bebida) {
                            setArrowAbsoluteBottom(!arrowAbsoluteBottom);
                            setExibirComparadoresMenor(!exibirComparadoresMenor);
                        } else {
                            setExibirComparadoresMaior(false)
                            setArrowAbsoluteBottom(true);
                            setExibirComparadoresMenor(true);
                        };
                    }}
                >
                    {comparadoresMenorQuantidade.length}
                </ExibirMenorButton>
            )}
            {(arrowAbsoluteBottom && bebidaComparadorAtiva === bebida) && (
                <ArrowAbsoluteBottom />
            )}
            {bebida.bebida} {bebida.volume}
        </EscadaBebidaContainer>
    );
};

export default EscadaBebida;