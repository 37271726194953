import { AdminBebidaEditarContainer, ContentContainer } from './styles';
import BebidaForm from '../Forms/BebidaForm';
import { ReactSVG } from 'react-svg';
import CadastroBebidasSvg from '../../assets/cadastro-bebidas.svg';
import Loading from '../Loading';
import { useEffect, useState } from 'react';
import api from '../../services/api';
import { useAuth } from '../../providers/Auth';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const AdminBebidaEditar = () => {
    return (
        <AdminBebidaEditarContainer>
            <div className='header'>
                <ReactSVG src={CadastroBebidasSvg}/> <h1>Editar Bebida</h1> 
            </div>
            <ContentContainer>
                <BebidaForm />
            </ContentContainer>
        </AdminBebidaEditarContainer>
    );
};

export default AdminBebidaEditar;