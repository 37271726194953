import { AdminComercialContainer, ContentContainer, PesquisarInputContainer, SelectContainer, TableContainer, TotalComerciaisContainer } from './styles';
import { ReactComponent as PesquisarSvg } from '../../assets/pesquisar.svg';
import { ReactComponent as DownloadSvg } from '../../assets/download.svg';
import { ReactSVG } from 'react-svg';
import VisualizarSvg from '../../assets/visualizar.svg';
import ComercialSvg from '../../assets/comercial.svg';
import { useState } from 'react';
import Loading from '../Loading';
import { Link, useNavigate } from 'react-router-dom';
import CustomTable from '../CustomTable';
import Pager from '../Pager';
import { useAuth } from '../../providers/Auth';
import { paginador } from '../../utils/pager';
import api from '../../services/api';
import { useEffect } from 'react';
import ComercialTr from '../Trs/ComercialTr';
import Select from 'react-select';
import HomeFiltroSvg from '../../assets/home-filtro.svg';

const AdminComercial = () => {
    const [comerciais, setComerciais] = useState([]);

    const [geosOptions, setGeosOptions] = useState([]);
    const [geoSelecionada, setGeoSelecionada] = useState('');

    const [pesquisa, setPesquisa] = useState('');
    const [comerciaisFiltrados, setComerciaisFiltrados] = useState([]);
    const [comerciaisPaginados, setComerciaisPaginados] = useState([]);

    const [paginaAtual, setPaginaAtual] = useState(1);
    const [totalPaginas, setTotalPaginas] = useState(1);

    const [comerciaisLoaded, setComerciaisLoaded] = useState(false);
    const [geosLoaded, setGeosLoaded] = useState(false);
    const [loading, setLoading] = useState(true);

    const { logout, user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (comerciaisLoaded && geosLoaded){
            setLoading(false);
        };
    }, [comerciaisLoaded, geosLoaded]);

    const buscarComerciais = () => {
        setComerciaisLoaded(false);
        api.get('/comerciais')
            .then((response) => {
                setComerciais(response.data.data.comerciais);
                setComerciaisLoaded(true);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                };
            });
    };

    const buscarGeos = () => {
        setGeosLoaded(false);
        api.get('/geos')
            .then((response) => {
                const geosOptions = response.data.data.geos.map((geo) => {
                    return {
                        value: geo.id,
                        label: geo.nome
                    };
                });
                setGeosOptions(geosOptions);
                setGeosLoaded(true);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                };
            });
    };

    useEffect(() => {
        buscarComerciais();
        if (user.permissao === 'ac') {
            buscarGeos();
        } else {
            setGeosLoaded(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[user]);

    useEffect(() => {
        const comerciaisFiltrados = comerciais.filter((comercial) => {
            const filtroId = pesquisa ? String(comercial.id) === String(pesquisa) : true;
            const filtroNome = comercial.nome.toLowerCase().includes(pesquisa.toLowerCase());
            const filtroGeo = geoSelecionada ? String(comercial.geo_id) === String(geoSelecionada.value) : true;

            return (filtroNome || filtroId) && filtroGeo;
        });
        setComerciaisFiltrados(comerciaisFiltrados);
    }, [comerciais, pesquisa, geoSelecionada]);

    useEffect(() => {
        const paginado = paginador(comerciaisFiltrados, paginaAtual);
        setComerciaisPaginados(paginado.itens);
        setPaginaAtual(paginado.paginaAtual);
        setTotalPaginas(paginado.paginas);
    }, [comerciaisFiltrados, paginaAtual]);

    const criaRelatorio = () => {

    };

    return (
        <AdminComercialContainer>
            <div className="header">
                <ReactSVG src={ComercialSvg} /> <h1>Cadastro de Comercial</h1>
            </div>
            {loading ? (
                <Loading />
            ) : (                
                <ContentContainer>
                    <div className='header'> 
                        <PesquisarInputContainer>
                            <input 
                                type='text'
                                id='pesquisar-comercial'
                                placeholder='Pesquisar comercial'
                                value={pesquisa}
                                onChange={(e)=>setPesquisa(e.target.value)}
                            />
                            <label htmlFor='pesquisar-comercial'>
                                <PesquisarSvg />
                            </label>
                        </PesquisarInputContainer>
                        {/* <button onClick={criaRelatorio} className='extrair'>
                            <DownloadSvg /> extrair lista
                        </button> */}
                        <Link to='/administrativo/comercial/novo' className='novo'>
                            Novo
                        </Link>
                    </div>
                    {user.permissao === 'ac' && (
                        <SelectContainer>
                            <label>
                                <ReactSVG src={HomeFiltroSvg} /> Filtrar por Geo
                            </label>
                            <Select 
                                options={geosOptions}
                                value={geoSelecionada}
                                onChange={(e) => setGeoSelecionada(e)}
                                placeholder='Ver todos'
                                className='filtro-select-container'
                                classNamePrefix='filtro-select'
                                isClearable
                                noOptionsMessage={() => 'Nenhuma geo encontrada'}
                            />
                        </SelectContainer>
                    )}
                    <TotalComerciaisContainer>
                        <ReactSVG src={VisualizarSvg} /> {`Mostrando ${comerciaisFiltrados.length} ${comerciaisFiltrados.length === 1 ? 'comercial' : 'comerciais'}`}
                    </TotalComerciaisContainer>
                    <TableContainer>
                        <CustomTable 
                            header={['Id', 'Comercial', `${user.permissao === 'ac' ? 'Geo' : ''}`, 'Criação', ' ']} 
                            message='Nenhum comercial encontrado.'
                        >
                            {comerciaisPaginados.map((comercial) => {
                                return <ComercialTr key={comercial.id} comercial={comercial} />
                            })}
                        </CustomTable>
                        <Pager currentPage={paginaAtual} setCurrentPage={setPaginaAtual} totalPages={totalPaginas} />
                    </TableContainer>
                </ContentContainer>
            )}
        </AdminComercialContainer>
    );
};

export default AdminComercial;