import { ReactSVG } from 'react-svg';
import { ContentContainer, DegrausContainer, ModalEscadaPrecoContainer } from './styles'
import CustomModal from '../CustomModal';
import EscadaDePrecosSvg from '../../../assets/escada-preco.svg';
import MaisCaroSvg from  '../../../assets/mais-caro.svg';
import MaisBaratoSvg from '../../../assets/mais-barato.svg';
import EscadaDegrau from '../../EscadaDegrau';
import { useState } from 'react';
import { useAuth } from '../../../providers/Auth';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import api from '../../../services/api';
import { toast } from 'react-toastify';
import Loading from '../../Loading';
import { range } from '../../../utils/range';

const ModalEscadaPreco = ({ setModalOpened }) => {
    const [escadaPreco, setEscadaPreco] = useState('');
    const [degraus, setDegraus] = useState([]);
    const [escadaBebidas, setEscadaBebidas] = useState([]);

    const { user, logout } = useAuth();
    const navigate = useNavigate();
    
    const [escadaPrecoLoaded, setEscadaPrecoLoaded] = useState(false);
    const [escadaBebidasLoaded, setEscadaBebidasLoaded] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (escadaPrecoLoaded && escadaBebidasLoaded) {
            setLoading(false);
        } else {
            setLoading(true);
        };
    }, [escadaPrecoLoaded, escadaBebidasLoaded]);
    
    const buscarEscadaPreco = () => {
        setEscadaPrecoLoaded(false);
        api.get('/escadas-precos')
            .then((response) => {
                const [escada] = response.data.data.escadas;
                setDegraus(range(escada.degraus_cima, escada.degraus_baixo));
                setEscadaPreco(escada);
                setEscadaPrecoLoaded(true);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                    return;
                };
                toast.error('Erro ao buscar escada de preço.');
            });
    };

    useEffect(() => {
        buscarEscadaPreco();
    }, []);

    
    const buscarEscadaBebidas = () => {
        setEscadaBebidasLoaded(false);
        api.get(`/escada-bebida-volumes/${escadaPreco.id}`)
            .then((response) => {
                const escadaBebidasOrdenadas = response.data.data.volumes.sort((a, b) => {
                    if (a.posicao > b.posicao) {
                        return 1
                    } else if (a.posicao < b.posicao) {
                        return -1
                    }
                });
                setEscadaBebidas(escadaBebidasOrdenadas);
                setEscadaBebidasLoaded(true);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                    return;
                };
                toast.error('Erro ao buscar bebidas da escada de preço.');
            });
    };

    useEffect(() => {
        if (escadaPreco) {
            buscarEscadaBebidas()
        };
    }, [escadaPreco]);

    return (
        <ModalEscadaPrecoContainer>
            <CustomModal setModalOpened={setModalOpened}>
                <ContentContainer>
                    <h1><ReactSVG src={EscadaDePrecosSvg} /> Escada de preços</h1>
                    {loading ? (
                        <Loading />
                    ) : (
                        <div className='content'>
                            <h2>Os itens com ícones <p className='maior' title='Maior igual'/> ou <p className='menor' title='Menor igual' />além de iguais podem ser maiores ou menores que o item ligado à ele, clique sobre o ícone para saber a diferença.</h2>
                            <div className='caption'>
                                <ReactSVG src={MaisCaroSvg} /> <p>Bebidas <b>mais caras</b> acima</p>
                            </div>
                            <DegrausContainer>
                                {degraus.map((degrau) => (
                                    <EscadaDegrau key={degrau} degrau={degrau} escadaPreco={escadaPreco} escadaBebidas={escadaBebidas} buscarEscadaBebidas={buscarEscadaBebidas} visualizar/>
                                ))}
                            </DegrausContainer>
                            <div className='caption'>
                                <ReactSVG src={MaisBaratoSvg} /> <p>Bebidas <b>mais baratas</b> abaixo</p>
                            </div>
                        </div>
                    )}
                </ContentContainer>
            </CustomModal>
        </ModalEscadaPrecoContainer>
    );
};

export default ModalEscadaPreco;