import { useState } from 'react';
import CustomModal from '../CustomModal';
import { ContentContainer, ModalAdicionarEscadaBebidasContainer, SalvarButton, SelectContainer } from './styles';
import Select from 'react-select';
import SalvarSvg from '../../../assets/salvar.svg';
import { ReactSVG } from 'react-svg';
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { useAuth } from '../../../providers/Auth';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const ModalAdicionarEscadaBebidas = ({ setModalOpened, escadaPreco, degrau, escadaBebidas, buscarEscadaBebidas }) => {
    const [bebidasOptions, setBebidasOptions] = useState([]);
    const [bebidasSelecionadas, setBebidasSelecionadas] = useState([]);

    const { user, logout } = useAuth();
    const navigate = useNavigate();

    const buscarBebidasVolumes = () => {
        api.get('/bebida-volumes')
            .then((response) => {
                const escadaBebidasFiltradas = response.data.data.volumes.filter((elem) => {
                    return !escadaBebidas.some((item) => item.bebida_volume_id === elem.id)
                });
                const options = escadaBebidasFiltradas.map((elem) => {
                    return {
                        value: elem.id,
                        label: elem.bebida + ' ' + elem.volume,
                    };
                });
                setBebidasOptions(options);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                    return;
                };
                toast.error('Erro ao buscar bebidas.');
            });
    };

    useEffect(() => {
        buscarBebidasVolumes();
    }, []); 

    const handleAdicionarBebidaVolumes = () => {
        const dados = {
            degrau,
            volumes: bebidasSelecionadas.map((elem) => {
                return {
                    bebida_volume_id: elem.value
                }
            })
        };
        api.post(`escada-bebida-volumes/${escadaPreco.id}`, dados)
            .then((response) => {
                buscarEscadaBebidas();
                setModalOpened(false);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                    return;
                };
                toast.error('Erro ao adicionar itens a escada.');
            })
    };

    return (
        <ModalAdicionarEscadaBebidasContainer>
            <CustomModal setModalOpened={setModalOpened}>
                <ContentContainer>
                    <h1>Adicionar bebida</h1>
                    <SelectContainer style={{width: '100%'}}>
                        <label htmlFor='bebidas'>Bebidas</label>
                        <Select
                            id='bebidas'
                            className='react-select-container'
                            classNamePrefix='react-select'
                            placeholder='Selecione as bebidas'
                            isMulti
                            options={bebidasOptions}
                            onChange={setBebidasSelecionadas}
                        />
                    </SelectContainer>
                    <div className='buttons-container'>
                        <SalvarButton onClick={handleAdicionarBebidaVolumes}>
                            <ReactSVG src={SalvarSvg} /> Salvar
                        </SalvarButton>
                    </div>
                </ContentContainer>
            </CustomModal>
        </ModalAdicionarEscadaBebidasContainer>
    );
};

export default ModalAdicionarEscadaBebidas;