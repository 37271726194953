import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth } from '../../../providers/Auth';
import api from '../../../services/api';
import CustomModal from '../CustomModal';
import { ButtonsContainer, ContentContainer, ModalFinalizarPedidoContainer } from './styles';
import { useState } from 'react';
import Loading from '../../Loading';

const ModalFinalizarPedido = ({ pedido, setModalOpened, levaAtual, handleGeraPdf, aguardarPedido }) => {
    const [loading, setLoading] = useState(false);
    
    const { logout, user } = useAuth();
    const navigate = useNavigate();

    const handleFinalizarPedido = async () => {
        setLoading(true);
        const erro = await handleGeraPdf(true);
        if (erro) {
            return setModalOpened(false);
        };
        let dados = {
            status: 'AGUARDANDO',
            leva_id: levaAtual.id,
        };
        if (user.permissao !== 'rn' && !aguardarPedido) {
            dados.status = 'APROVADO';
        };
        api.patch(`/pedidos/${pedido.id}/status`, dados)
            .then((response) => {
                navigate('/pedidos');
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                    return;
                };
                if (error.response?.status === 409 ){
                    toast.error('Usuário com pedidos em aberto.');
                    setLoading(false);
                    return;
                };
                toast.error('Não foi possível finalizar o pedido. Tente novamente mais tarde.')
                setLoading(false);
            });
    };

    return (
        <ModalFinalizarPedidoContainer>
             <CustomModal setModalOpened={setModalOpened}>
                {loading ? (
                    <Loading />
                ) : (
                    <ContentContainer>
                        

                        
                        
                        {(aguardarPedido)? (
                            <p>Certeza que deseja enviar o pedido para <span>aguardando?</span></p>
                        ):(
                            <p>Certeza que deseja enviar o pedido para <span>aprovação?</span></p>
                        )}
                            <ButtonsContainer>
                            <button onClick={async () => await handleFinalizarPedido()}>
                                SIM
                            </button>
                            <button onClick={() => setModalOpened(false)}>
                                NÃO
                            </button>
                        </ButtonsContainer>
                    </ContentContainer>
                )}
            </CustomModal>
        </ModalFinalizarPedidoContainer>
    )
};

export default ModalFinalizarPedido;